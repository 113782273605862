<template>
  <div class="c-bitts-status">
    <div
      v-if="status === 'success' && !disabled"
      :class="statusClasses"
      data-testid="bitts-status__success"
      class="c-bitts-status__extra-message success"
    >
      <FontAwesomeIcon
        v-if="successText"
        :icon="['fak', 'check-circle']"
        :style="{ height: '12px', width: '12px' }"
        class="text-success-accent"
      />
      <span>{{ successText }}</span>
    </div>
    <div
      v-if="status === 'danger' && !disabled"
      data-testid="bitts-status__danger"
      class="c-bitts-status__extra-message danger"
      :class="statusClasses"
    >
      <FontAwesomeIcon
        v-if="computedDangerText"
        :icon="['fak', 'exclamation-circle']"
        :style="{ height: '12px', width: '12px' }"
        class="text-danger-accent"
      />
      <span>
        {{ computedDangerText }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { Ref, computed, isRef } from 'vue';

import { BittsClasses } from '../types';
export interface BittsStatusProps {
  status?: 'default' | 'danger' | 'success'; // TODO: Streamline, use error/danger consistently, add to BittsColor
  statusClasses?: BittsClasses;
  dangerText?: string | Ref<string>;
  successText?: string;
  disabled?: boolean;
}
export const BittsStatusDefaults = {
  status: 'default',
  statusClasses: '',
  dangerText: "Something isn't quite right",
  successText: 'Looking good',
  disabled: false,
} satisfies Required<BittsStatusProps>;
</script>
<script setup lang="ts">
const {
  status = 'default',
  statusClasses = '',
  dangerText = "Something isn't quite right",
  successText = 'Looking good',
  disabled = false,
} = defineProps<BittsStatusProps>();

const computedDangerText = computed(() => {
  return isRef(dangerText) ? dangerText.value : dangerText;
});
</script>

<style lang="pcss" scoped>
.c-bitts-status {
  .c-bitts-status__extra-message {
    @apply text-sm mt-8 flex gap-4;

    &.success {
      @apply text-success-text-strong;
    }

    &.danger {
      @apply text-danger-text-strong;
    }
  }
}
</style>
